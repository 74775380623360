let currentPage = null;

const isDevEnv =
  process.env.REACT_APP_ENV === undefined ||
  process.env.REACT_APP_ENV === 'development' ||
  process.env.REACT_APP_ENV === 'test'
    ? true
    : false;

const initSegment = () => {
  // do not track on dev its not set
  if (isDevEnv) {
    return;
  }

  var analytics = (window.analytics = window.analytics || []);
  if (!analytics.initialize)
    if (analytics.invoked)
      window.console &&
        console.error &&
        console.error('Segment snippet included twice.');
    else {
      analytics.invoked = !0;
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
      ];
      analytics.factory = function(t) {
        return function() {
          var e = Array.prototype.slice.call(arguments);
          e.unshift(t);
          analytics.push(e);
          return analytics;
        };
      };
      for (var t = 0; t < analytics.methods.length; t++) {
        var e = analytics.methods[t];
        analytics[e] = analytics.factory(e);
      }
      analytics.load = function(t, e) {
        var n = document.createElement('script');
        n.type = 'text/javascript';
        n.async = !0;
        n.src =
          'https://cdn.segment.com/analytics.js/v1/' + t + '/analytics.min.js';
        var a = document.getElementsByTagName('script')[0];
        a.parentNode.insertBefore(n, a);
        analytics._loadOptions = e;
      };
      analytics.SNIPPET_VERSION = '4.1.0';
      analytics.load('AKaiAOzNLGTRTNXKmCRNfmnozXUn27Qe');
    }
};

const trackPage = page => {
  // do not track local dev environments unless bit is set
  if (isDevEnv) {
    return;
  }

  initSegment();

  window.analytics.page(page.pathname, {
    search: page.search,
    hash: page.hash,
    environment: process.env.REACT_APP_ENV,
    siteId: page.state?.siteId,
    siteName: page.state?.sites?.find(site => site.id === page.state.siteId)?.settings?.displayName,
  });
};

export const segementAnalytics = store => next => action => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    const nextPage = action.payload.location;
    if (currentPage !== nextPage) {
      currentPage = nextPage;
      currentPage.state = store.getState()?.auth;
      trackPage(nextPage);
    }
  }
  return next(action);
};
